import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HelpOutline as HelpOutlineIcon, Close as CloseIcon } from '@mui/icons-material';
import { setContextFileLoading,setDrawerOpen, getFlashcardsAPI, getQuizAPI } from '../../store';
import NorthIcon from '@mui/icons-material/North';
import customLog from '../../customLogger';


const StyledTextField = styled(TextField)(({ theme, themeData }) => ({
  backgroundColor: 'transparent',
  marginTop: '0px',
  borderRadius: '10px',
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    padding: '8px 12px',
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiFormLabel-root': {
    color: themeData.color5,
    '&.Mui-focused': {
      color: themeData.color15
    }
  },
  '& .MuiInputBase-input': {
    color: themeData.color5,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: themeData.color5,
    borderWidth: '1px',
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: themeData.color15,
    borderWidth: '2px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: themeData.color15,
    borderWidth: '2px',
  },
}));

const FlashcardsQuizMeDialog = ({ setIsPaneExpanded, open, onClose, type, onFlashcardFileUpload, onQuizFileUpload, createFlashcards, createQuiz }) => {
  const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);
  const [activeTab, setActiveTab] = useState(0);
  const [prompt, setPrompt] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePromptSubmit = (event) => {
    event.preventDefault();
    dispatch(setDrawerOpen(false));
    setIsPaneExpanded(true);
    if (type === 'flashcards') {
      // dispatch(getFlashcardsAPI(prompt));
      createFlashcards({message: prompt});
    } else if (type === 'quizme') {
      // dispatch(getQuizAPI(prompt));
      createQuiz({message: prompt});
    }
    customLog('HANDLEPROMPTSUBMIT right before onClose()');
    onClose();
  };

  const handleSendClick = () => {
    handlePromptSubmit({ preventDefault: () => {} });  // Call the handlePromptSubmit function directly
};

  const handleFileUpload = (event) => {
    dispatch(setDrawerOpen(false));
    setIsPaneExpanded(true);
    dispatch(setContextFileLoading(true));
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (type === 'flashcards') {
        
        // onFlashcardFileUpload(file);
        // dispatch(getFlashcardsAPI(''));
        createFlashcards({file: file});
      } else if (type === 'quizme') {
        // onQuizFileUpload(file);
        // dispatch(getQuizAPI(''));
        createQuiz({file: file});
      }
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`${type}-dialog-title`}
      PaperProps={{
        style: {
          background: `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`,
          color: themeData.color5,
          borderRadius: '10px',
          width: '400px',
        },
      }}
    >
      <DialogTitle id={`${type}-dialog-title`} sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {type === 'flashcards' ? 'Create Flashcards' : 'Create Quiz'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          textColor={themeData.color4}
          indicatorColor="primary"
          TabIndicatorProps={{
            style: { backgroundColor: themeData.color15 },
          }}
          sx={{
            '& .MuiTab-root': {
              color: themeData.color5,
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '10%',
                width: '80%',
                height: '2px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s',
              },
            },
            '& .Mui-selected': {
              color: themeData.color15,
              '&::after': {
                backgroundColor: themeData.color15,
              },
            },
          }}
        >
          <Tab label="Prompt" />
          <Tab label="Upload" />
        </Tabs>
        <Box sx={{ p: 2 }}>
          {activeTab === 0 && (
            <form onSubmit={handlePromptSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    //border: `1px solid ${themeData.color5}`,
                    marginBottom: '12px',
                    transition: 'all 0.3s ease-in-out',
                    boxShadow: `0 0 5px rgba(0, 0, 0, 0.3)`,
                    '&:hover': {
                      //border: `1px solid ${themeData.color15}`,
                      boxShadow: `0 0 5px ${themeData.color15}`,
                    },
                  }}
                >
                  <StyledTextField
                    fullWidth
                    margin="normal"
                    placeholder="Enter a topic or prompt"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    themeData={themeData}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        '& .MuiInputBase-input': {
                            color: themeData.color5,
                        },
                      },
                    }}
                    variant="filled"
                    sx={{ flexGrow: 1, color: themeData.color5 }}
                  />
                  <IconButton
                    onClick={handleSendClick}
                    sx={{
                      color: themeData.color5,
                      marginRight: '12px',
                      '&:hover': {
                        color: themeData.color15,
                      },
                    }}
                  >
                    <NorthIcon />
                  </IconButton>
                </Box>
            </form>
          )}
          {activeTab === 1 && (
            <Box>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: 'transparent',
                  color: themeData.color5,
                  border: `1px solid ${themeData.color5}`,
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: themeData.color15,
                    border: `1px solid ${themeData.color15}`,
                  },
                  '&:focus': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: themeData.color15,
                    border: `1px solid ${themeData.color15}`,
                  },
                }}
              >
                Choose File
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 2, color: themeData.color5 }}>
                  Selected file: {selectedFile.name}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FlashcardsQuizMeDialog;