import React, { useRef, useState, useCallback} from 'react';
import {FormControlLabel, Checkbox, Button, Stack, Box, AppBar, Drawer, Toolbar, IconButton, Typography, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import 'highlight.js/styles/default.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {setFakeLoginSignupScreenOpen, setLoginSignupScreenOpen, setDrawerOpen, uploadLongtermFileAPI, uploadContextFileAPI, handleNewChat, setCoursePopover, setSnackbarShareChatOpen,
        setSnackbarRecordingOpen, setSnackbarRecordingMessage, setIsShortTermMemory,
        setIsLongTermMemory, setGeneralSnackbarMessage, setGeneralSnackbarOpen,
        setConversationID, setFlashcardsScreenOpen, setQuizMeScreenOpen} from '../../store.js';
import { useSelector, useDispatch } from 'react-redux';
import MemoryCheckbox from './MemoryCheckbox.js';
import customLog from '../../customLogger.js';
import './AppBar.css';

const DjangoHost = process.env.REACT_APP_DJANGO_HOST;

const TopAppBar = () => {
    //Initialize dispatch
    const dispatch = useDispatch();

    /* FILES */
    // Reference for file input
    const fileInputRef = useRef(null);


    // URL for file preview for recently uploaded file
    const [filePreview, setFilePreview] = useState(null);

    // State for showing upload file dialog
    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);

    // State for setting selected file
    const [selectedFile, setSelectedFile] = useState(null);

    // State for if short-term memory checkbox is checked
    const isShortTermMemory = useSelector((state) => state.chat.isShortTermMemory)

    // Maximum file size
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    // Memoized function call for setisShortTermMemory
    const handleShortTermMemoryChange = useCallback((e) => {
        dispatch(setIsShortTermMemory(e.target.checked));
    }, []);

    // State for current conversationID
    const currentConversationID = useSelector((state) => state.chat.currentConversationID);

    // State for current course ID
    const selectedCourseId = useSelector((state) => state.chat.selectedCourse);

    // State for APIToken
    const APIToken = useSelector((state) => state.chat.APIToken);

    // State for if flashcardsScreenOpen
    const flashcardsScreenOpen = useSelector((state) => state.chat.flashcardsScreenOpen);

    // State for if quizMeScreenOpen
    const quizMeScreenOpen = useSelector((state) => state.chat.quizMeScreenOpen);

    // State for if long-term memory checkbox is checked
    const isLongTermMemory = useSelector((state) => state.chat.isLongTermMemory);

    // selector for colors of selected theme
    const themeData = useSelector((state) => state.chat.themeData);

    const handleLongTermMemoryChange = useCallback((e) => {
        dispatch(setIsLongTermMemory(e.target.checked));
    }, []);

    // State for left drawer
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);

    // Button style for Upload
    const uploadButtonStyle = {
        border: '1px solid #000',
        flex: 1,
        margin: '0 5px',
        color: themeData.color5,
        backgroundColor: themeData.color3,
        '&:hover': {
            backgroundColor: themeData.color4,
        },
        midWidth: 0, //Allows buttons to fully shrink
        padding: '6px 12px',
        textTransform: 'none',
    };

    // Function to handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) { //File size exceeds limit
                dispatch(setGeneralSnackbarMessage("File size exceeds 5MB limit"));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
            // Handle the file, such as setting state or uploading
            // console.log(file.name); // Example action
            setSelectedFile(file); // Assuming you have a state to hold the selected file
            setFilePreview(URL.createObjectURL(file)); // Create preview URL
            setShowUploadFileDialog(true); // Open the dialog
        }
    };

    /* RECORDING */
    // State for recording
    const [isRecording, setIsRecording] = useState(false);

    const handleOpenLoginDialog = () => {
        // setActiveTab(0); // Set to Login tab
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 0}));
    };
    
    const handleOpenSignupDialog = () => {
        // setActiveTab(1); // Set to Sign Up tab
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
    };

    // Snackbar recording timeout reference
    const snackbarTimeoutRef = useRef(null);

    // State for snackbar recording
    const snackbarRecordingOpen = useSelector((state) => state.chat.snackbarRecordingOpen);

    // Function for toggling recording
    const toggleRecording = () => {
        setIsRecording(!isRecording);

        //clear existing timeoutID to reset timer
        clearTimeout(window.snackbarTimer);

        dispatch(setGeneralSnackbarMessage(isRecording ? "Recording stopped" : "Recording has started! Click the stop icon to discontinue"));
        dispatch(setGeneralSnackbarOpen(true));

        window.snackbarTimer = setTimeout(() => {
            dispatch(setGeneralSnackbarOpen(false));
        }, 3000);

    };

    /* SHARE CHAT */
    // State for showing share chat dialog
    const [showShareChatDialog, setShowShareChatDialog] = useState(false);

    // Function to toggle the drawer
    const toggleDrawer = () => {
        customLog('toggleDrawer called with drawerOpen of ' + drawerOpen);
        customLog('!drawerOpen is ' + !drawerOpen);
        dispatch(setDrawerOpen(!drawerOpen));
    };

    // State for share chat link
    const [shareLink, setShareLink] = useState('');

    // Function to handle share chat click
    const handleShareChatClick = () => {
        //TODO: Temporary dummy link, replace with actual link using redux/backend
        const dummyLink = "dummylink.com";
        navigator.clipboard.writeText(dummyLink)
        .then(() => {
            // show snackbar for successful copy
            clearTimeout(window.snackbarTimer);
            dispatch(setGeneralSnackbarMessage("Link copied to clipboard!"));
            dispatch(setGeneralSnackbarOpen(true));
            // close snackbar after 3 seconds  
            window.snackbarTimer = setTimeout(() => {
                dispatch(setGeneralSnackbarOpen(false));
            }, 3000);
        }).catch(err => console.log("Error copying link: ", err));
        };
        // setShareLink(dummyLink);
        // setShowShareChatDialog(true);
    // };

    // Dialog for sharing chat
    const ShareChatDialog = ({ open, onClose, link }) => {
        // Function to copy link to clipboard
        const copyLinkToClipboard = () => {
            navigator.clipboard.writeText(link).then(() => {
                alert('Link copied to clipboard'); // Provide feedback or handle this more elegantly in your UI
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        };
    
        return (
            <Dialog open={open} onClose={onClose} PaperProps={{ style: { backgroundColor: themeData.color2, color: themeData.color5 } }}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{ position: 'absolute', right: 2, top: 2, color: themeData.color5 }}
                    >
                        <CloseIcon />
                    </IconButton>    
                </DialogTitle>
                <DialogContent dividers style= {{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant="body1" style={{ marginBottom: 16}}>
                        {link}
                    </Typography>
                    {/* <IconButton onClick={copyLinkToClipboard} style={{ color: themeData.color5 }}>
                        <ContentCopyIcon /> 
                    </IconButton> */}
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        );
    };

    /* DRAWER CLOSED CASE */
    const studentCourses = useSelector((state) => state.chat.courses);
    // State for the title of the selected course TODO: Bug here for displaying course name
    const selectedCourseTitle = studentCourses[selectedCourseId];

    // Function for handling course click to enable popover
    const handleCourseClick = (event) => {
        dispatch(setCoursePopover(event.currentTarget)); //specifies parent that was clicked so that popover is properly anchored to it
    };

    const FilePreviewDialog = ({ open, onClose, filePreview, fileName }) => {

        // Function to handle file upload
        const handleUpload = async () => {
            if (selectedFile) {            
                if (isShortTermMemory) {
                    // If current conversation ID is null 
                    if (!currentConversationID) {
                        customLog('shortTermMemory, no conversationID - one being created');
                        try { //TODO: Replicated code from chat.js, story in central place & use
                            const conversationResponse = await fetch(DjangoHost+"/api/conversation/", {
                                method: "POST",
                                headers: { "Content-Type": "application/json",
                                            Authorization:  `Bearer ${APIToken}`},
                                body: JSON.stringify({course_id: selectedCourseId})                     
                                });
                            const data = await conversationResponse.json();
                            if (conversationResponse.ok) {
                            // console.log(data);
                            let conversation_id = data.conversation_id;
                            dispatch(setConversationID(conversation_id));
                            // dispatch(addNewChat(conversation_id));
                            // console.log('conversation_id right before createChatTitle dispatch is ' + conversation_id);
                            } else {
                                // console.log('error');
                            }
                            
                            } catch (error) {
                            console.error("Error:", error);
                            alert("Error", "Failed to register");
                        }
                    }

                    // Assuming uploadContextFileAPI expects the file as its argument
                    dispatch(uploadContextFileAPI(selectedFile))
                        .unwrap()
                        .then(() => {
                            // console.log("File uploaded successfully");
                            // Handle success, maybe close the dialog or show a success message
                        })
                        .catch((error) => {
                            console.error("Failed to upload file: ", error);
                            // Handle error, maybe show an error message
                        });
                }
                
                if (isLongTermMemory) {
                    dispatch(uploadLongtermFileAPI(selectedFile))
                    .unwrap()
                    .then(() => {
                        // console.log("File uploaded successfully");
                        // Handle success, maybe close the dialog or show a success message
                    })
                    .catch((error) => {
                        console.error("Failed to upload file: ", error);
                        // Handle error, maybe show an error message
                    });
                }

            }

            onClose(); // Close dialog after attempting to upload
            dispatch(setIsLongTermMemory(false));
            dispatch(setIsShortTermMemory(false));
        };

        return (
            <Dialog 
                open={open} 
                onClose={onClose} 
                aria-labelledby="file-preview-dialog-title" 
                PaperProps={{ 
                    style: {
                        backgroundColor: themeData.color2, 
                        borderRadius: '20px', 
                        color: themeData.color5,
                        border: '1px solid #000'}}}>
                <DialogTitle id="file-preview-dialog-title"></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '20px' }}>
                            {/* <img src={filePreview}  /> */}
                            <Typography variant="subtitle1">
                                {fileName.length > 20 ? (
                                    <Tooltip title={fileName}>
                                        <span> {`${fileName.substring(0, 20)}...`}</span>
                                    </Tooltip>
                                ) : (
                                    fileName)}
                            </Typography>
                        </div>
                        <div>
                            <MemoryCheckbox
                                label="Short-term Memory"
                                checked={isShortTermMemory}
                                onChange={handleShortTermMemoryChange}
                                tooltip="Use uploaded file immediately in current conversation"
                            />
                            <MemoryCheckbox
                                label="Long-term Memory"
                                checked={isLongTermMemory}
                                onChange={handleLongTermMemoryChange}
                                tooltip="Let Plato keep file for long-term use to personalize responses to you"
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpload} style={uploadButtonStyle}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    
    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
    const signUpButtonStyle = {
        color: themeData.color5,
        backgroundColor: themeData.color2,
        borderRadius: "30px",
        textTransform: 'none',
        padding: '4px 12px',
        minWidth: '80px',
        fontSize: '0.875rem',
    };

    const handleSignUpClick = () => {
        dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
    };

    return (
        <AppBar position="fixed" style={{ zIndex: 1100, backgroundColor: 'transparent', margin: 0, boxShadow: 'none' }}> {/*TODO: below div is repeated in code, store elsewhere */}
            <Toolbar style={{ minHeight: '70px', padding: 10 }}>
                <Stack direction="row" justifyContent="start">
                {/* <Box> */}

                </Stack>
                {/* </Box> */}

                <div style={{ flexGrow: 1 }} /> {/* This pushes the buttons to the right */}

                    {/* <ShareChatDialog open={showShareChatDialog} onClose={() => setShowShareChatDialog(false)} link={shareLink} /> */}
                    <FilePreviewDialog open={showUploadFileDialog} onClose={() => setShowUploadFileDialog(false)} filePreview={filePreview} fileName={selectedFile ? selectedFile.name : ''} />
                
                    {!isLoggedIn && (
                        <div style={{ 
                        }}>
                            <Button 
                                style={{...signUpButtonStyle, marginRight: '10px'}} 
                                onClick={handleOpenLoginDialog} // Open Login tab
                            >
                                Login
                            </Button>
                            <Button 
                                style={signUpButtonStyle} 
                                onClick={handleOpenSignupDialog} // Open Sign Up tab
                            >
                                Sign Up
                            </Button>
                        </div>
                    )}
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;
