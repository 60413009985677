import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  Note as FlashcardsIcon,
  Quiz as QuizIcon,
  School as GuidedLessonIcon,
  UploadFile as UploadIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { 
  setLoginSignupScreenOpen, 
  setFlashcardsScreenOpen, 
  setQuizMeScreenOpen,
  getAllFlashcards,
  getAllQuizzes,
  handleNewChat,
  setAbortion
} from '../../store.js';

const OptionSelectionDialog = ({ open, setDialogOpen, onOptionSelect, onUploadClick }) => {
  const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);

  const options = [
    {
      label: 'Flashcards',
      icon: <FlashcardsIcon fontSize="large" />,
      description: 'Study with flashcards',
    },
    {
      label: 'Quizzes',
      icon: <QuizIcon fontSize="large" />,
      description: 'Test your knowledge',
    },
    {
      label: 'Guided Lesson',
      icon: <GuidedLessonIcon fontSize="large" />,
      description: 'Coming soon!',//'Have Plato teach you anything',
      isHighlighted: true,
      isDisabled: true,
    },
    {
      label: 'Upload',
      icon: <UploadIcon fontSize="large" />,
      description: 'Upload your materials',
    },
    {
      label: 'Chat',
      icon: <ChatIcon fontSize="large" />,
      description: 'Ask Plato questions',
    },
  ];

  const handleOptionClick = (option) => {
    if (onOptionSelect && !option.isDisabled) {
      onOptionSelect(option.label);
      
      switch (option.label) {
        case 'Flashcards':
          if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
          } else {
            setDialogOpen(false);
            dispatch(setFlashcardsScreenOpen(true));
            dispatch(getAllFlashcards());
          }
          break;
        case 'Quizzes':
          if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
          } else {
            setDialogOpen(false);
            dispatch(setQuizMeScreenOpen(true));
            dispatch(getAllQuizzes());
          }
          break;
        case 'Upload':
          if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
          } else {
            setDialogOpen(false);
            onUploadClick();
          }
          break;
        case 'Chat':
          if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
          } else {
            setDialogOpen(false);
            dispatch(handleNewChat());
            dispatch(setAbortion(true));
          }

          break;
        default:
          break;
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setDialogOpen(false)}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          background: `linear-gradient(to top, ${themeData.color7}, ${themeData.color8})`,
          borderRadius: '20px',
          color: themeData.color5,
          width: '80%',
          maxWidth: '800px',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          color: themeData.color5,
        }}
      >
        Select a Mode
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '16px',
            mt: '20px',
            mb: '20px',
          }}
        >
          {options.map((option) => (
            <Button
              key={option.label}
              onClick={() => handleOptionClick(option)}
              disabled={option.isDisabled}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: option.isHighlighted 
                  ? `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`
                  : 'transparent',
                color: option.isHighlighted ? themeData.color1 : themeData.color5,
                textTransform: 'none',
                borderRadius: '20px',
                padding: '16px',
                '&:hover': {
                  backgroundColor: option.isDisabled ? 'transparent' : 'linear-gradient(to top, #E8EAE3, #EAECE5)',
                },
                transform: option.isHighlighted ? 'scale(1.05)' : 'none',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                boxShadow: option.isHighlighted 
                  ? '0px 6px 12px rgba(0, 0, 0, 0.2)' 
                  : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                cursor: option.isDisabled ? 'not-allowed' : 'pointer',
                opacity: option.isDisabled ? 0.5 : 1,
              }}
            >
              {React.cloneElement(option.icon, { 
                style: { 
                  color: option.isHighlighted ? themeData.color1 : themeData.color5,
                  fontSize: option.isHighlighted ? '2.5rem' : '2rem',
                } 
              })}
              <Typography 
                variant="h6" 
                sx={{ 
                  mt: 1, 
                  color: option.isHighlighted ? themeData.color1 : themeData.color5,
                  fontWeight: option.isHighlighted ? 'bold' : 'normal',
                  fontSize: option.isHighlighted ? '1.2rem' : '1rem',
                }}
              >
                {option.label}
              </Typography>
              <Typography
                variant="body2"
                sx={{ 
                  mt: 0.5, 
                  textAlign: 'center', 
                  color: option.isHighlighted ? themeData.color1 : themeData.color5,
                  fontWeight: option.isHighlighted ? 'medium' : 'normal',
                }}
              >
                {option.description}
              </Typography>
            </Button>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OptionSelectionDialog;
