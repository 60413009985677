import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import { loginUserGoogleAPI, setGeneralSnackbarMessage, setGeneralSnackbarOpen, loginUserAPI, registerUserAPI, getInitialUserData, setLoginSignupScreenOpen } from '../../store.js';
import { useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import GoogleIcon from '@mui/icons-material/Google';
import customLog from '../../customLogger.js';
const StyledTextField = styled(TextField)(({ theme, themeData }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${themeData.color5}`,
  borderRadius: '10px',
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: `1px solid ${themeData.color15}`,
    },
  },
  '& .MuiFormLabel-root': {
    color: themeData.color5, // Default text color
    '&.Mui-focused': {
      color: themeData.color15 // Text color when the input is focused
    }
  },
  '& .MuiInputBase-input': {
    color: themeData.color5, // Text color for input
  }
}));

const LoginSignupDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['jwt']);
  const themeData = useSelector((state) => state.chat.themeData);
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
  const loginSignupScreenOpen = useSelector((state) => state.chat.loginSignupScreenOpen);

  // const [activeTab, setActiveTab] = useState(1);
  const loginSignupTab = useSelector((state) => state.chat.loginSignupTab);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleTabChange = (event, newValue) => {
    // setActiveTab(newValue);
    dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: newValue}));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return (
      password.length >= minLength &&
      hasLowercase &&
      hasUppercase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const result = await dispatch(loginUserAPI({userName: email, password, signup: false}));
    if (loginUserAPI.fulfilled.match(result)) {
      const { APIToken } = result.payload;
      setCookie('jwt', APIToken, {path: '/', secure: true, sameSite: 'strict'});
      dispatch(getInitialUserData({random: true}));
      dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: false, loginSignupTab: 0}));
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    // Check if email is valid
    if (!validateEmail(email)) {
      dispatch(setGeneralSnackbarMessage("Please enter a valid email address."));
      dispatch(setGeneralSnackbarOpen(true));
      return;
    }

    if (!validatePassword(password)) {
      dispatch(setGeneralSnackbarMessage("Password must be at least 8 characters with an uppercase and lowercase letter, number, and special character."));
      dispatch(setGeneralSnackbarOpen(true));
      return;
    }

    const result = await dispatch(registerUserAPI({
      user_name: email,
      password,
      first_name: "FirstName",
      last_name: "lastName",
      email,
      is_student: true,
      is_individual: true
    }));
    if (registerUserAPI.fulfilled.match(result)) {
      dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen:false, loginSignupTab: 1}));
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const authorization_code = credentialResponse.credential;
      console.log('Authorization Code:', authorization_code);
  
      // Dispatch action to send authorization code to backend
      const result = await dispatch(loginUserGoogleAPI({ authorization_code, provider: 'google' }));
  
      if (loginUserGoogleAPI.fulfilled.match(result)) {
        const { APIToken } = result.payload;
        setCookie('jwt', APIToken, { path: '/', secure: true, sameSite: 'strict' });
        dispatch(getInitialUserData({ random: true }));
        dispatch(setLoginSignupScreenOpen({ loginSignupScreenOpen: false, loginSignupTab: 0 }));
      }
    } catch (error) {
      console.error('Google Login Error:', error);
      dispatch(setGeneralSnackbarMessage("Google Login failed. Please try again."));
      dispatch(setGeneralSnackbarOpen(true));
    }
    customLog('handleGoogleSuccess');
  };

      // Define the error handler for Google Login
  const handleGoogleError = () => {
    // console.error('Google Login Failed');
    // dispatch(setGeneralSnackbarMessage("Google Login failed. Please try again."));
    // dispatch(setGeneralSnackbarOpen(true));
    customLog('handleGoogleError reached');
  };

  return (
    <Dialog
      open={loginSignupScreenOpen}
      onClose={() => dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: false, loginSignupTab: 1}))}
      aria-labelledby="login-signup-dialog-title"
      PaperProps={{
        style: {
          background: `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`,
          color: themeData.color5,
          borderRadius: '10px',
          width: '400px', // Set the width to 400px
          maxWidth: '90vw', // Ensure it doesn't exceed 90% of the viewport width on smaller screens
        },
      }}
    >
      <DialogTitle id="login-signup-dialog-title" sx={{ fontWeight: 'bold' }}>
        Welcome to MyPlato
        <IconButton
          aria-label="close"
          onClick={() => dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: false, loginSignupTab: 1}))}
          sx={{ 
            position: 'absolute',
            right: 8,
            top: 8,
            color: themeData.color5 
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs 
          value={loginSignupTab} 
          onChange={handleTabChange} 
          centered
          textColor="inherit"
          indicatorColor="primary"
          TabIndicatorProps={{
            style: { backgroundColor: themeData.color15 },
          }}
          sx={{
            '& .MuiTab-root': {
              color: themeData.color5,
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
            },
            '& .Mui-selected': {
              color: themeData.color15,
            },
          }}
        >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        <Box sx={{ p: 3 }}>
          {loginSignupTab === 0 && (
            <Box>
            <div className="google-login-container">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                  flow="auth-code"
                  redirectUri="https://myplato.ai/chat"
                  useOneTap
                  text="continue_with"
                  width="100%" // Change this to 100% to match the dialog width
                />
              </div>
            <div style={{
              display: 'flex', 
              alignItems: 'center',  
              margin: '20px 0', // Add vertical margin for spacing
            }}>
              <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
              <Typography 
                variant="body2" 
                align="center" 
                sx={{ margin: '0 10px', color: themeData.color5, fontWeight: 'bold' }}
              >
                OR
              </Typography>
              <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
            </div>

            <form onSubmit={handleLogin}>
              <StyledTextField
                fullWidth
                margin="normal"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                themeData={themeData}
                InputProps={{
                    disableUnderline: true
                }}
                variant="filled"
              />
              <StyledTextField
                fullWidth
                margin="normal"
                label="Password"
                type="password"
                value={password}
                themeData={themeData}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Tooltip title="All passwords must be at least 8 characters and contain 1 lowercase letter, 1 uppercase letter, a special character, and a number">
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                }}
                variant="filled"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 3, 
                  mb: 2, 
                  backgroundColor: 'transparent', 
                  color: themeData.color5,
                  border: `1px solid ${themeData.color5}`,
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: themeData.color15,
                    border: `1px solid ${themeData.color15}`,
                  },
                  '&:focus': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: themeData.color15,
                    border: `1px solid ${themeData.color15}`,
                  },
                }}
              >
                Login
              </Button>
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: themeData.color5, fontWeight: 'medium' }}>
                By logging in, you accept MyPlato's{' '}
                <Link to="/legal/terms-of-use" style={{ color: themeData.color15, textDecoration: 'none', fontWeight: 'bold' }}>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/legal/privacy-policy" style={{ color: themeData.color15, textDecoration: 'none', fontWeight: 'bold' }}>
                  Privacy Policy
                </Link>.
              </Typography>
            </form>
            </Box>
          )}
          {loginSignupTab === 1 && (
            <Box>
              <div className="google-login-container">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                  flow="auth-code"
                  redirectUri="https://myplato.ai/chat"
                  useOneTap
                  text="continue_with"
                  width="100%" // Change this to 100% to match the dialog width
                />
              </div>
              <div style={{
                display: 'flex', 
                alignItems: 'center', 
                margin: '20px 0', // Add vertical margin for spacing
              }}>
                <hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
                  <Typography 
                    variant="body2" 
                    align="center" 
                    sx={{ margin: '0 10px', color: themeData.color5, fontWeight: 'bold' }}
                  >
                  OR
                  </Typography>
                < hr style={{ flexGrow: 1, border: 'none', borderTop: '1px solid #ccc' }} />
              </div>
              <form onSubmit={handleSignUp}>
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  // type="email"
                  value={email}
                  themeData={themeData}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                      disableUnderline: true
                  }}
                  variant="filled"
                />
                <StyledTextField
                  fullWidth
                  margin="normal"
                  label="Password"
                  type="password"
                  value={password}
                  themeData={themeData}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <Tooltip title="All passwords must be at least 8 characters and contain 1 lowercase letter, 1 uppercase letter, a special character, and a number">
                          <IconButton>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                  }}
                  variant="filled"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ 
                    mt: 3, 
                    mb: 2, 
                    backgroundColor: 'transparent', 
                    color: themeData.color5,
                    border: `1px solid ${themeData.color5}`,
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: themeData.color15,
                      border: `1px solid ${themeData.color15}`,
                    },
                    '&:focus': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: themeData.color15,
                      border: `1px solid ${themeData.color15}`,
                    },
                  }}
                >
                  Sign Up
                </Button>
                <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: themeData.color5, fontWeight: 'medium' }}>
                  By signing up, you accept MyPlato's{' '}
                  <Link to="/legal/terms-of-use" style={{ color: themeData.color15, textDecoration: 'none', fontWeight: 'bold' }}>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link to="/legal/privacy-policy" style={{ color: themeData.color15, textDecoration: 'none', fontWeight: 'bold' }}>
                    Privacy Policy
                  </Link>.
                </Typography>
              </form>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginSignupDialog;