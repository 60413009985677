// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Chat from './chat.js';
import Home from "./Home.js";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login.js";
import { Provider, useSelector, useDispatch } from 'react-redux';
import {store, getInitialUserData, signOut, setAPIToken} from './store.js';
import customLog from './customLogger.js';
import ProtectedRoute from './ProtectedRoute.js';
import { CookiesProvider } from 'react-cookie';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js';
import TermsOfUse from './components/TermsOfUse/TermsOfUse.js';
import useIsMobile from './useIsMobile.js';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { jwtDecode } from 'jwt-decode';
import ReactGA from 'react-ga4';

// Initialize ReactGA with the measurement ID
ReactGA.initialize('G-3G1LSXWC65');

function App() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <Router>
            <InnerApp />
        </Router>
      </CookiesProvider>
    </Provider>
  );
}

function InnerApp() {
  customLog('InnerApp rendered');
  const dispatch = useDispatch(); 
  const themeData = useSelector((state) => state.chat.themeData);
  const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);
  const isMobile = useIsMobile();

  useEffect(() => {
    // Send pageview to ReactGA
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };

  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = getCookie('refreshToken');
      const response = await fetch('/api/token/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (response.ok) {
        const data = await response.json();
        document.cookie = `jwt=${data.access};path=/;secure;samesite=strict`;
        document.cookie = `refreshToken=${data.refresh};path=/;secure;samesite=strict`;
        dispatch(setAPIToken(data.access));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  useEffect(() => {
    customLog('APP.JS USEEFFECT for cookies');
    const jwtToken = getCookie('jwt');
    
    const checkTokenAndFetchData = async () => {
      if (jwtToken && isTokenValid(jwtToken)) {
        dispatch(setAPIToken(jwtToken));
        dispatch(getInitialUserData())
          .unwrap()
          .catch(() => {
            refreshAndFetchData();
          });
      } else {
        refreshAndFetchData();
      }
    };

    const refreshAndFetchData = async () => {
      customLog('REFRESHANDFETCHDATA');
      const refreshSuccessful = await refreshToken();
      if (refreshSuccessful) {
        dispatch(getInitialUserData())
          .unwrap()
          .catch(() => {
            dispatch(signOut());
          });
      } else {
        dispatch(signOut());
      }
    };

    checkTokenAndFetchData();
  }, [dispatch]);

  if (isMobile) {
    return (
      <div className="App" style={{ 
        background: `linear-gradient(to bottom, ${themeData.color8}, ${themeData.color7})`,
        textAlign: 'center', 
        padding: '20px' 
      }}>
        <h2>Mobile support coming soon! Check us out on a larger device!</h2>
      </div>
    );
  }

  return (
    <div className="App" style={{
      background: `linear-gradient(to bottom, ${themeData.color8}, ${themeData.color7})`,
      minHeight: '100vh'
    }}>
      <header className="App-header">
        <GoogleOAuthProvider clientId='182174617714-qm6fpd7dn1dsman1cp9422v25cs7vc1j.apps.googleusercontent.com'>
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} /> */}
            <Route path="*" element={<Navigate to="/chat" replace/>} />
            <Route 
              path="/chat" 
              element={
                  <Chat />
              } 
            />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
          </Routes>
        </GoogleOAuthProvider>
      </header>
    </div>
  );
}

export default App;
