import React, { useMemo } from 'react';
import { Typography, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { BlockMath, InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import customLog from '../../customLogger.js';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

// Component for rendering a single message
const MessageComponent = React.memo(({ message, username, platoImage, stickman, onLinkClick }) => {

    const renderTable = (tableContent) => {
        const rows = tableContent.map(row => 
            row.split('|').map(cell => cell.trim()).filter(cell => cell !== '')
        );
        const headers = rows[0];
        const body = rows.slice(2); // Skip the separator row

        return (
            <TableContainer component={Paper} style={{ marginBottom: '1em', backgroundColor: 'transparent' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={{ fontWeight: 'bold' }}>
                                    <ReactMarkdown>{header}</ReactMarkdown>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex}>
                                        <ReactMarkdown>{cell}</ReactMarkdown>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    
    const renderMessageContent = useMemo(() => {
        customLog('MESSAGECOMPONENT IS CALLED WITH MESSAGE ' + message.text);
        const lines = message.text.split('\n');
        const result = [];
        let isInCodeBlock = false;
        let currentLanguage = '';
        let codeBlockContent = '';
        let isInTable = false;
        let tableContent = [];

        lines.forEach((line, index) => {
            if (line.startsWith('```')) {
                if (isInCodeBlock) {
                    result.push(
                        <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                            <SyntaxHighlighter key={index} language={currentLanguage} style={dark}>
                                {codeBlockContent}
                            </SyntaxHighlighter>
                        </div>
                    );
                    isInCodeBlock = false;
                    currentLanguage = '';
                    codeBlockContent = '';
                } else {
                    isInCodeBlock = true;
                    currentLanguage = line.slice(3).trim() || 'text';
                }
            } else if (isInCodeBlock) {
                codeBlockContent += line + '\n';
            } else if (line.trim().startsWith('|') && line.trim().endsWith('|')) {
                if (!isInTable) {
                    isInTable = true;
                    tableContent = [];
                }
                tableContent.push(line);
            } else if (isInTable && line.trim() === '') {
                result.push(renderTable(tableContent));
                isInTable = false;
                tableContent = [];
            } else {
                const parts = line.split(/(\$\$.*?\$\$)/g);
                parts.forEach((part, partIndex) => {
                    if (part.startsWith('$$') && part.endsWith('$$')) {
                        result.push(
                            <BlockMath key={`${index}-${partIndex}`}>
                                {part.slice(2, -2)}
                            </BlockMath>
                        );
                    } else {
                        result.push(
                            <ReactMarkdown 
                                key={`${index}-${partIndex}`}
                                rehypePlugins={[rehypeRaw, rehypeKatex]}
                                remarkPlugins={[remarkMath]}
                                components={{
                                    p: ({node, ...props}) => <Typography variant="body1" style={{fontSize: '16px', marginBottom: '1em', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto'}} {...props} />,
                                    h1: ({node, ...props}) => <Typography variant="h4" style={{marginTop: '1em', marginBottom: '0.5em', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto'}} {...props} />,
                                    h2: ({node, ...props}) => <Typography variant="h5" style={{marginTop: '1em', marginBottom: '0.5em', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto'}} {...props} />,
                                    h3: ({node, ...props}) => <Typography variant="h6" style={{marginTop: '1em', marginBottom: '0.5em', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto'}} {...props} />,
                                    ul: ({ node, ...props }) => (
                                        <ul style={{fontSize: '16px', marginBottom: '1em', paddingLeft: '2em', listStyleType: 'disc', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }} {...props} />
                                    ),
                                    ol: ({ node, ...props }) => (
                                        <ol type="1" style={{fontSize: '16px', marginBottom: '1em', listStyleType: 'decimal', listStylePosition: 'inside', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }} {...props} />
                                    ),
                                    li: ({ node, ...props }) => (
                                        <li style={{fontSize: '16px', marginBottom: '0.5em', listStyleType: 'inherit', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }} {...props} />
                                    ),
                                    a: ({ node, id, title, children, ...props }) => (
                                        <Tooltip title={title || ''}>
                                            <a
                                                className="in-text-citation"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (onLinkClick) {
                                                        onLinkClick(id);
                                                    }
                                                }}
                                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}
                                                {...props}
                                            >
                                                {children}
                                            </a>
                                        </Tooltip>
                                    ),
                                    inlineMath: ({value}) => <InlineMath>{value}</InlineMath>
                                }}>
                                {part}
                            </ReactMarkdown>
                        );
                    }
                });
            }
        });

        if (isInCodeBlock) {
            result.push(
                <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                    <SyntaxHighlighter key={lines.length} language={currentLanguage} style={dark}>
                        {codeBlockContent}
                    </SyntaxHighlighter>
                </div>
            );
        }

        if (isInTable) {
            result.push(renderTable(tableContent));
        }

        return result;
    }, [message.text, onLinkClick]);
    const isUser = message.user === 'User';

    return (
        <div className={`message-container ${isUser ? 'user-message' : 'assistant-message'}`}>
            <img 
                src={isUser ? stickman : platoImage} 
                alt={isUser ? username : 'Assistant'} 
                className="profile-image"
            />
            <div className={`message-bubble ${isUser ? 'user-bubble' : 'assistant-bubble'}`}>
                {renderMessageContent}
            </div>
        </div>
    );
});

export default React.memo(MessageComponent);