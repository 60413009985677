import React from 'react';
import customLog from '../../customLogger.js';
import MessageComponent from './messageComponent.js';

const PreviousMessages = ({ messages, username, platoImage, stickman, onLinkClick }) => {
    customLog('PREVIOUSMESSAGES IS CALLED WITH messages of ' + JSON.stringify(messages));

    return (
        <>
            {messages.map((message, index) => (
                <MessageComponent
                    key={index}
                    message={message}
                    username={username}
                    platoImage={platoImage}
                    stickman={stickman}
                    onLinkClick={onLinkClick}
                />
            ))}
        </>
    );
};

export default React.memo(PreviousMessages);