export const themes = {
    default: {
        color1: '#0B2027', //screen text, black
        color2: '#40798C', //drawer
        color3: '#70A9A1', //buttons
        color4: '#CFD7C7', //background
        color5: '#F6F1D1', //text on buttons
    },
    stevo: {
        color1: '#0B2027',
        color2: '#40798C',
        color3: '#70A9A1',
        color4: '#F2AE9F',
        color5: '#F6F1D1',
    },
    fall: {
        color1: '#0B2027',
        color4: '#BC9F8B',
        color2: '#B5CFB7',
        color3: '#CADABF',
        color5: '#E7E8D8'
    },
    earth: {
        color1: '0B2027',
        color4: '#914F1E',
        color3: '#DEAC80',
        color2: 'F7DCB9',
        color5: '#B5C18E',
    },
    motherNature: {
        color1: '#F6F1D1', //light text
        color2: '#7E9D69', //green bottom drawer
        color3: '#D06F6E', //pink red top drawer
        color4: '#ded6c0', //sandy background
        //color4: '#353535',
        color5: '#F6F1D1', //dark text
    },
    combo: {
        color1: '#83937D', //light text on color 2 F6F1D1 claude - 8E8A7A
        color2: '#D9E4CE', //blue drawer 40798C 67a1ac rosy brown - #AC8887 claude - E6E1D3, D3DDC6 - light green
        color3: '#D06F6E', //pink-red
        color4: '#EBF2E8', //sandy background CFD7C7 ded6c0 claude - F2F0E9 light green - ECF1E4
        color5: '#404C37', //light text on color 4  F6F1D1
        color6: '#F2FAF2',
        color7: '#F4F5EF', //bottom of main background
        color8: '#EEF4EC', //top of main background
        color9: '#D8E3CF', //bottom of left drawer
        color10: '#E9EBDB', //top of left drawer
        color11: '#F1F1EE', //Bottom of chat input
        color12: '#F6F6F4', //Top of chat input
        color13: '#F3F5EF', //Bottom of assistant response
        color14: '#F4F6F2', //Top of assistant response
        color15: '#328E84', //MyPlato text, plato left sleeve
        color16: '#31AA9E', //MyPlato 2nd left sleeve
        color17: '#4BE5C5', //MyPlato 3rd left sleeve
        color18: '#99B9A8', //MyPlato hair
    },
    dark: {
        color1: '#CBD5C3', // Lighter text for contrast on darker backgrounds
        color2: '#354A3C', // Darker version of the original blue drawer; a deep muted green
        color3: '#A94B4A', // Muted pink-red to match the dark mode palette
        color4: '#2E3830', // Darker background; a deep olive/charcoal
        color5: '#A8B49A', // Light text on the new dark background (color 4)
        color6: '#1A211B', // Very dark green for high contrast background
        color7: '#232923', // A deep charcoal-green for bottom of the main background
        color8: '#1C251F', // Dark top of the main background; nearly black with a hint of green
        color9: '#394D40', // Darker shade for bottom of left drawer
        color10: '#2E4237', // A slightly lighter but still dark color for top of left drawer
        color11: '#2A332C', // Bottom of chat input; deep green-gray for a dark mode effect
        color12: '#232C27', // Top of chat input; darker for contrast
        color13: '#2C342F', // Bottom of assistant response; a medium-dark gray-green
        color14: '#242D29', // Top of assistant response; darkened to match overall palette
        color15: '#50C2B6', // Bright turquoise for MyPlato text; contrast with the darker theme
        color16: '#4AD2C1', // Second bright turquoise for MyPlato, maintaining vibrancy
        color17: '#73EBD7', // Lighter turquoise for MyPlato, to keep the sleeves vibrant
        color18: '#678575', // Muted, darker green for MyPlato hair to match the dark mode
    }
}