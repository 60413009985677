import React, { useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, IconButton, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Close as CloseIcon, AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import FlashcardsQuizMeDialog from './flashcardsQuizMeDialog';
import PreviousItemsDialog from './PreviousItemsDialog';
import { setMemoryScreenOpen, setFlashcardsScreenOpen, setQuizMeScreenOpen, setGeneralSnackbarMessage, setGeneralSnackbarOpen, uploadLongtermFileAPI } from '../../store';
import customLog from '../../customLogger';


const CreateOrLibrary = ({ setIsPaneExpanded, retrieveQuiz, retrieveFlashcards, retrieveMemory, open, onClose, type, onFlashcardFileUpload, onQuizFileUpload, createFlashcards, createQuiz }) => {
    const dispatch = useDispatch();
  const themeData = useSelector((state) => state.chat.themeData);

  const LONGTERM_MAX_FILE_SIZE = 50 * 1024 * 1024;

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showLibraryDialog, setShowLibraryDialog] = useState(false);

  const handleCreateClick = () => {
    setShowCreateDialog(true);
  };

  const handleLibraryClick = () => {
    setShowLibraryDialog(true);
  };

  const handleCloseDialogs = () => {
    customLog('HANDLECLOSEDIALOGS CALLED');
    if (type === 'flashcards') {
        dispatch(setFlashcardsScreenOpen(false));
    } else if (type === 'quizme') {
        dispatch(setQuizMeScreenOpen(false));
    } else if (type ===  'memory') {
        dispatch(setMemoryScreenOpen(false));
    }
    setShowCreateDialog(false);
    setShowLibraryDialog(false);
    //onClose();  // Closes the main dialog as well
  };    
  
  // File input ref
  const fileInputRef = useRef(null);

    // Handle Upload Click
    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }

    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        dispatch(setMemoryScreenOpen(false));
        const file = event.target.files[0];
        if (file) {
            // Dispatch the API call with the selected file
            if (file.size > LONGTERM_MAX_FILE_SIZE) {
                dispatch(setGeneralSnackbarMessage("File size exceeds 50MB limit"));
                dispatch(setGeneralSnackbarOpen(true));
                return;
            }
            dispatch(uploadLongtermFileAPI(file));
            
        }
    };

  return (
    <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${type}-dialog-title`}
            PaperProps={{
                style: {
                background: `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`,
                color: themeData.color5,
                borderRadius: '10px',
                width: '400px',
                },
            }}
        >
            <DialogTitle
                id={`${type}-dialog-title`}
                sx={{
                fontWeight: 'bold',
                textAlign: 'center', // Center the title
                }}
            >
                {type === 'flashcards' ? 'Flashcards' : (type === 'quizme' ? 'Quizzes' : 'Memory')}
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: themeData.color5
                }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', mb: '10px', mt: '10px' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: 'transparent',
                            border: `1px solid ${themeData.color5}`,
                            color: themeData.color5,
                            textTransform: 'none',
                            borderRadius: '20px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                color: themeData.color15,
                                border: `1px solid ${themeData.color15}`,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={type === 'memory' ? handleUploadClick : handleCreateClick}
                    >
                        {type === 'memory' ? 'Upload' : 'Create'}
                        {type !== 'memory' && <AutoAwesomeIcon sx={{ marginLeft: '8px' }} />}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: 'transparent',
                            color: themeData.color5,
                            border: `1px solid ${themeData.color5}`,
                            textTransform: 'none',
                            borderRadius: '20px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                color: themeData.color15,
                                border: `1px solid ${themeData.color15}`,
                            },
                        }}
                        onClick={handleLibraryClick}
                        >
                        Library
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
        {type === 'memory' && (
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        )}
        {showCreateDialog && (
            <FlashcardsQuizMeDialog
                open={showCreateDialog}
                onClose={handleCloseDialogs}
                type={type}
                onFlashcardFileUpload={onFlashcardFileUpload}
                onQuizFileUpload={onQuizFileUpload}
                createFlashcards={createFlashcards}
                createQuiz={createQuiz}
                setIsPaneExpanded={setIsPaneExpanded}
            />
        )}

      {showLibraryDialog && (
        <PreviousItemsDialog
            open={showLibraryDialog}
            onClose={handleCloseDialogs}
            type={type}
            retrieveFlashcards={retrieveFlashcards}
            retrieveQuiz={retrieveQuiz}
            retrieveMemory={retrieveMemory}
            setIsPaneExpanded={setIsPaneExpanded}
        />
      )}
    </>
  );
};

export default CreateOrLibrary;
