import React, { useRef, useState, useEffect } from 'react';
import { Drawer, Divider, TextField, Button, List, ListItem, ListItemText,
        AppBar, Toolbar, IconButton, Menu, MenuItem, Typography, Box, Tooltip, FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import ArrayDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import platoImage from '../../../images/newPlatoNoBG.png';
import stickman from '../../../images/stickman-nobg.png';
import flashcardStack from '../../../images/flashcardStack.png'
import CircularProgress from '@mui/material/CircularProgress';
import io from 'socket.io-client';
import { Provider, useSelector, useDispatch } from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import configureStore from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { getAllFiles, getAllQuizzes, getAllFlashcards, setMemoryScreenOpen, setLoginSignupScreenOpen, setDrawerOpen, deleteCourseAPI, updateCourseAPI, setGeneralSnackbarMessage, setGeneralSnackbarOpen, 
    createCourseAPI, setSelectedResource, removeChat, editChatTitle, setConversationID, setAbortion,
    setEditedChatTitleRedux, sendChatMessage, createConversation, setChatTitle, setMessages, handleLoadChatRedux, 
    createCourse, editCourseName, removeCourse, setSelectedCourse, loadMostRecentChat, setFlashcardsScreenOpen } from '../../../store.js';

import 'highlight.js/styles/default.css';
import SettingsIcon from '@mui/icons-material/Settings';
import {removeChatAPI, editChatTitleAPI, handleNewChat, setCoursePopover, setSystemPrompt, setSettingsOpen, setQuizMeScreenOpen} from '../../../store.js';
import './BottomLeftDrawer.css';
import { createSelector } from 'reselect';
import customLog from '../../../customLogger.js';
import QuizIcon from '@mui/icons-material/Quiz';
import StyleIcon from '@mui/icons-material/Style';
import LoginSignupDialogComponent from '../../LoginSignupDialog/LoginSignupDialogComponent.js';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';



const BottomLeftDrawer = ( {setIsPaneExpanded}) => {

    // Initialize dispatch
    const dispatch = useDispatch();

    // State for left drawer
    const drawerOpen = useSelector((state) => state.chat.drawerOpen);

    // User info for firstName
    const firstName = useSelector((state) => state.chat.firstName); 

    // User info for username
    const username = useSelector((state) => state.chat.username);
    

    /* COURSES */
    // State for course popover
    const coursePopover = useSelector((state) => state.chat.coursePopover);

    // State for the courses of the student
    const studentCourses = useSelector((state) => state.chat.courses);

    // State for the courseID of the selected course
    const selectedCourseId = useSelector((state) => state.chat.selectedCourse);

    const isLoggedIn = useSelector((state) => state.chat.isLoggedIn);

    // State for the title of the selected course TODO: Bug here for displaying course name
    const selectedCourseTitle = studentCourses[selectedCourseId];

    // State for name of new course being created
    const [newCourseName, setNewCourseName] = useState("");

    // State for index of course name being edited
    const [editingCourseIndex, setEditingCourseIndex] = useState(null);

    // State for new name of course being edited
    const [editedCourseName, setEditedCourseName] = useState("");

    // Variable for if course is being created
    const creatingCourse = useSelector((state) => state.chat.creatingCourse);

    // Ref for the course button
    const courseButtonRef = useRef(null);

    // Variable for most recent 5 chat titles of current course
    const mostRecentChats = useSelector((state) => state.chat.currentOrderedChatIDs);

    // Selector for getting theme data
    const themeData = useSelector((state) => state.chat.themeData);

    // Function for handling course popover close
    const handleCourseClose = () => {
        // console.log("handleCourseClose called");
        dispatch(setCoursePopover(null));
        setNewCourseName("");
        setEditingCourseIndex(null);
        // document.body.focus();
    };

    // Function for handling course click to enable popover
    const handleCourseClick = (event) => {
        dispatch(setCoursePopover(event.currentTarget)); //specifies parent that was clicked so that popover is properly anchored to it
    };

    // Function to handle course name change
    const handleCourseNameChange = (event) => {
        setEditedCourseName(event.target.value);
    };

    // Function to handle course name change submission
    const handleCourseNameUpdate = (event, index) => {
        if(event.key === 'Enter') {
            // dispatch(editCourseName({courseName: editedCourseName, courseId: index}));
            // console.log('enter pressed on course name update');
            dispatch(updateCourseAPI({courseId: index, courseName: editedCourseName}));
            setEditingCourseIndex(null);
        }
    };

    // Function to handle edit button click
    const handleEditCourseClick = (index) => {
        setEditingCourseIndex(index);
        setEditedCourseName(studentCourses[index]);
    };

    // Function to handle removing a course
    const handleRemoveCourse = (courseId) => {
        dispatch(deleteCourseAPI({courseId, courseName: studentCourses[courseId]}));
        //if currently selected course is being removed
        if (selectedCourseId === courseId) {
            dispatch(setSelectedCourse(null));
            dispatch(handleNewChat());
        }
        // dispatch(removeCourse({courseId: index}));
    };

    // Function to handle new course submission
    const handleAddCourse = (event) => {
        event.preventDefault();
        handleCourseClose();
        if (creatingCourse) {
            dispatch(setGeneralSnackbarMessage("Please wait, course is being created"));
            dispatch(setGeneralSnackbarOpen(true));
            
            
        } else {
            dispatch(createCourseAPI(newCourseName));
            dispatch(handleNewChat());
            setNewCourseName("");
        }
        

    };

    // Function to handle selecting a new course
    const handleCourseSelect = (courseId) => {//TODO: Weird bug where sometimes it doesn't change course on first click
        // console.log("handleCourseSelect called");
        dispatch(setSelectedCourse(courseId));
        // dispatch(loadMostRecentChat(courseId));
        dispatch(handleNewChat());
    };

    /* CHATS */
    // State for ID of chat title being edited
    const [editingChatID, setEditingChatID] = useState(null);

    // State for new chat title being edited
    const [editedChatTitle, setEditedChatTitle] = useState("");

    // Obtain specific course's chats
    // Using reselect to memoize the courseConvoIDsToChatTitle to avoid unnecessary rerenders
    const selectChatsBase = state => state.chat.IDToChatTitle[state.chat.selectedCourse] || {};
    const selectChats = createSelector(
        [selectChatsBase],
        chats => chats
    );
    let courseConvoIDsToChatTitle = useSelector(selectChats);

    // Handle loading a chat
    const handleLoadChat = (chatTitle) => {
        // const currentCourse = studentCourses.find(course => course.title === chatTitle);
        dispatch(handleLoadChatRedux(chatTitle)); //TODO: Send currentCourse? probs not, selectedCourse on redux side
        dispatch(setAbortion(true));
    };

    // Function to handle edit chat title click
    const handleEditChatTitleClick = (conversationID, chatTitle) => {
        // console.log('handleEditChatTitleClick called with conversationID: ', conversationID, ' and chatTitle: ', chatTitle);
        setEditingChatID(conversationID); //TODO: Change this to index
        setEditedChatTitle(chatTitle);
    };

    // Function to submit new chat title
    const handleChatTitleUpdate = (event, conversationID) => {
        if (event.key === 'Enter') {
            // console.log('about to dispatch editChatTitleAPI with conversationID: ', conversationID, ' and newTitle: ', event.target.value);
            dispatch(editChatTitleAPI({conversationID, newTitle: event.target.value}));
            setEditingChatID(null);
        };
    };

    // Function to handle upgrade now click
    const handleUpgradeNowClick = async () => {
        try {
            const response = await fetch('/create-checkout-session', {method: 'POST'});
            const session = await response.json();

            // Redirect to Stripe Checkout using session URL
            window.location.href = session.url;
        } catch (error) {
            // console.log('Error redirecting to Stripe Checkout: ', error);
        }
    };

    // Function to handle create flashcards click
    const handleCreateFlashcards = async () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setFlashcardsScreenOpen(true));
            dispatch(getAllFlashcards());
        }
    };

    // Function to handle Memory button click
    const handleMemoryClick = async () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({ loginSignupScreenOpen: true, loginSignupTab: 1 }));
        } else {
            dispatch(setMemoryScreenOpen(true));
            dispatch(getAllFiles());
        }
    };


    // Function to handle quiz me click
    const handleQuizMe = async () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setQuizMeScreenOpen(true));
            dispatch(getAllQuizzes());
        } 
    }

    // Function to handle removing chats
    const handleRemoveChat = (conversationID) => {
        dispatch(removeChatAPI({conversationID}));
    };

    /* SETTINGS */
    // State for settings popup
    const settingsOpen = useSelector((state) => state.chat.settingsOpen);

    // Function to handle settings popup open
    const handleSetSettingsOpen = () => {
        if (!isLoggedIn) {
            dispatch(setLoginSignupScreenOpen({loginSignupScreenOpen: true, loginSignupTab: 1}));
        } else {
            dispatch(setSettingsOpen(true));
        }
    };

    /* EXTRA OPTIONS */
    // State for socratic method
    const [socraticMethod, setSocraticMethod] = useState(false);

    const handleSocraticMethodChange = (event) => {
        const newValue = event.target.checked;
        customLog('HANDLESOCRATICMETHODAHNGE called with newValue of ' + newValue);
        setSocraticMethod(newValue);
        if (newValue) {
            customLog('HINTS ONLY MODE ON');
            dispatch(setSystemPrompt(("socratic")));
        } else {
            customLog('HINTS ONLY MODE OFF');
            dispatch(setSystemPrompt(("normal")));
        }
    };

    /* TOP LEFT DRAWER PREVIOUS FUNCTIONS */
    // Function to toggle the drawer
    const toggleDrawer = () => {
        customLog('toggleDrawer called with drawerOpen of ' + drawerOpen);
        customLog('!drawerOpen is ' + !drawerOpen);
        if (!drawerOpen) {
            setIsPaneExpanded(drawerOpen);
        }
        dispatch(setDrawerOpen(!drawerOpen));
        document.documentElement.style.setProperty('--drawer-width', drawerOpen ? '240px' : '50px');
    };

    return (
        <>
            <Drawer // For chat panel on left side
                variant="persistent" //does not close automatically
                anchor="left"
                open={drawerOpen}
                PaperProps = {{
                    className: 'drawer',
                    style: { 
                        background: `linear-gradient(to top, ${themeData.color9}, ${themeData.color10})`,
                        border: 'none' 
                    } 
                    }}
                >
                    <div className="drawer-content" style={{color: themeData.color5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 4px'}}>
                        <Tooltip title={drawerOpen ? "Close Drawer" : "Open Drawer"}>
                            <IconButton
                                onClick={toggleDrawer}
                                style={{
                                    color: themeData.color5,
                                    borderRadius: '50%',
                                    padding: '10px',
                                }}
                                
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>

                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    padding: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '45px',
                                    height: '45px',
                                }}
                            >
                                <span style={{
                                    color: themeData.color15,
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}>MyPlato</span>
                            </div>
                        </div>
                        <Tooltip title="Create New Chat">
                            <IconButton
                                onClick={() => {
                                    dispatch(handleNewChat());
                                    dispatch(setAbortion(true));
                                }}
                                style={{
                                    color: themeData.color5,
                                    borderRadius: '50%',
                                    padding: '10px',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ position: 'absolute', top: '108px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                        <Button className="learningToolButton"
                            onClick={handleMemoryClick}
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '0px' }}>
                                <PsychologyIcon style={{width: '20px', height: '20px', marginRight: '15px', color: themeData.color5}} />
                                <span style={{ textTransform: 'none', color: themeData.color5 }}> Memory </span>
                            </div>
                        </Button>
                    </div>
                    <div style={{ position: 'absolute', top: '36px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                        <Button className="learningToolButton"
                            onClick={handleCreateFlashcards} 
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '0px'}}>
                                <StyleIcon style={{width: '20px', height: '20px', marginRight: '15px', color: themeData.color5}} />
                                <span style={{ textTransform: 'none', color: themeData.color5}}> Flashcards </span>
                            </div>
                        </Button>
                    </div>
                    <div style={{ position: 'absolute', top: '72px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                        <Button className="learningToolButton"
                            onClick={handleQuizMe}
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '0px'}}>
                                <QuizIcon style={{width: '20px', height: '20px', marginRight: '15px', color: themeData.color5}} />
                                <span style={{ textTransform: 'none', color: themeData.color5}}> Quizzes </span>
                            </div>
                        </Button>
                    </div>
                    <div style={{position: 'absolute', top: '185px', width: '100%', display: 'flex', justifyContent: 'left', padding: '4px 0', paddingLeft: '15px', alignItems: 'center' }}>
                        <span style={{ color: themeData.color5, fontSize: '15px', fontWeight: '550' }}>Chat History</span>
                    </div>
                            <List className="list" style={{ marginTop: '160px' }}> {/* maps out saved chats */}
                                {mostRecentChats.map(conversationID => {
                                    const chatTitle = courseConvoIDsToChatTitle[conversationID];
                                    return (
                                    <ListItem 
                                        button 
                                        key={conversationID} 
                                        onClick={() => handleLoadChat(conversationID)} 
                                        style={{ 
                                            padding: '0px 0',  // Increased vertical padding
                                            paddingLeft: '15px',
                                            marginBottom: '0px'  // Added margin between items
                                        }}
                                    >
                                        <ChatBubbleOutlineIcon style={{width: '20px', height: '20px', marginRight: '10px', color: themeData.color5}} />
                                        {editingChatID === conversationID ? (
                                            <TextField
                                                autoFocus
                                                value={editedChatTitle}
                                                onChange={(e) => setEditedChatTitle(e.target.value)}
                                                onKeyDown={(event) => handleChatTitleUpdate(event, conversationID)}
                                            />
                                        ) : (
                                            <ListItemText 
                                                primary={chatTitle} 
                                                style={{ color: themeData.color5 }}
                                                primaryTypographyProps={{ 
                                                    style: { 
                                                        fontSize: '14px',  // Increased font size
                                                        fontWeight: '500'  // Added some weight to make it more visible
                                                    } 
                                                }}
                                            />
                                        )}
                                        <Box sx={{ flexGrow: 1}} />
                                    </ListItem>
                                    
                                );})}
                            </List>
                            <div style={{ position: 'absolute', bottom: '50px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                                <div 
                                    className="learningToolButton"
                                    style={{
                                        width: '100%',
                                        padding: '6px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'default',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                >
                                    <div style={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        width: '100%',
                                        paddingLeft: '10px'
                                    }}>
                                        <Checkbox
                                            checked={socraticMethod}
                                            onChange={handleSocraticMethodChange}
                                            style={{ 
                                                color: themeData.color5,
                                                marginRight: '15px',
                                                padding: 0
                                            }}
                                            size="small"
                                        />
                                        <span style={{ textTransform: 'none', color: themeData.color5 }}> Hints Only </span>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Tooltip title="Provides hints instead of direct answers">
                                            <HelpOutlineIcon style={{ marginLeft: '10px', fontSize: '20px', color: themeData.color5 }} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div style={{ position: 'absolute', bottom: '5px', width: '95%', padding: '5px', display: 'flex', alignItems: 'center' }}>
                                <Button 
                                    className="learningToolButton"
                                    onClick={() => handleSetSettingsOpen()}
                                    fullWidth
                                    style={{ height: '35px' }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        padding: '0 10px'
                                    }}>
                                        <div style={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            maxWidth: 'calc(100% - 34px)'}}>
                                            <img src={stickman} alt="stickman" style={{ 
                                                height: '24px', 
                                                width: '24px', 
                                                borderRadius: '50%', 
                                                marginRight: '10px' }} />
                                            <span style={{ 
                                                textTransform: 'none',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                color: themeData.color5 }}>{firstName || username}</span>
                                        </div>
                                        <SettingsIcon style={{ color: themeData.color5 }} />
                                    </div>
                                </Button>
                            </div>
                </Drawer>
            
            {!drawerOpen && (
                <div style={{
                    position: 'fixed',
                    left: '12px',
                    top: '10px', // Adjust this value to position the icons at the desired height
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0px', // Reduced gap between icons
                    zIndex: 1200
                }}>
                    <Tooltip title="Open Drawer">
                        <IconButton onClick={toggleDrawer} style={{ color: themeData.color5 }}>
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create New Chat">
                        <IconButton 
                            onClick={() => {
                                dispatch(handleNewChat());
                                dispatch(setAbortion(true));
                            }} 
                            style={{ color: themeData.color5 }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Flashcards">
                        <IconButton onClick={handleCreateFlashcards} style={{ color: themeData.color5 }}>
                            <StyleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Quizzes">
                        <IconButton onClick={handleQuizMe} style={{ color: themeData.color5 }}>
                            <QuizIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Memory">
                        <IconButton onClick={handleMemoryClick} style={{ color: themeData.color5 }}>
                            <PsychologyIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </>
    );
};

export default BottomLeftDrawer;