import React from 'react';
import customLog from '../../customLogger.js';
import MessageComponent from './messageComponent.js';

// Component for the currently streaming message
const StreamingMessage = React.memo(({ message, username, platoImage, stickman, onLinkClick }) => {
    customLog('STREAMINGMESSAGE IS CALLED with message is ' + message.text);
    if (!message.text) {
        return null;
    }

    return (
        <MessageComponent 
            message={message}
            username={username}
            platoImage={platoImage}
            stickman={stickman}
            onLinkClick={onLinkClick}
        />
    );
});

export default StreamingMessage;